<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <notifications group="foo" position="top right" />
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Pesanan</h3>
            </div>
            <div class="container-fluid mt-4">
                <div class="row gy-3">
                    <div class="col-12 col-md-4">
                        <div class="position-relative">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg pl-search w-search border-search"
                                    placeholder="Cari nama customer"
                                    v-model="search"
                                />
                                <button
                                    class="btn btn-primary px-4"
                                    type="button"
                                    @click="filterData()"
                                >
                                    Cari
                                </button>
                            </div>
                            <span
                                class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                            >
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-2">
                        <button
                            class="btn btn-outline-yellow btn-filter px-3 w-100"
                            @click="isFilter = !isFilter"
                        >
                            <img
                                src="../assets/dashboard/filter.png"
                                class="me-1"
                                width="18"
                                alt=""
                            />
                            Filter
                        </button>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                        <a
                            href="/order/cashier/create"
                            class="btn btn-primary px-3 btn-pesanan d-flex align-items-center justify-content-center"
                        >
                            <i class="pi pi-plus me-1 size-12"></i> Buat Pesanan
                        </a>
                    </div>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="container-fluid">
                    <div class="row gy-3">
                        <div class="col-12 col-md-4">
                            <label class="fw-bold mb-1">Kota Asal</label>
                            <multiselect
                                v-model="origin"
                                :options="origins"
                                label="name"
                                track-by="id"
                                placeholder="Cari dan pilih kota asal"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-md-8">
                            <label class="fw-bold mb-1">Kota Tujuan</label>
                            <div class="row gy-3">
                                <div class="col-12 col-md-6">
                                    <multiselect
                                        v-model="destination"
                                        :options="destinations"
                                        label="name"
                                        track-by="id"
                                        placeholder="Cari dan pilih kota tujuan"
                                        class="multi-form-custom"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div
                                        class="d-flex flex-column flex-md-row gap-2"
                                    >
                                        <button
                                            class="btn btn-outline-yellow flex-grow-1"
                                            @click="clearFilter()"
                                            :disabled="
                                                origin == null &&
                                                destination == null
                                            "
                                        >
                                            Clear
                                        </button>
                                        <button
                                            class="btn btn-green flex-grow-1"
                                            :disabled="
                                                origin == null &&
                                                destination == null
                                            "
                                            @click="filterData()"
                                        >
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-4">
                <div class="row gy-3 align-items-center">
                    <div class="col-12 col-md-6">
                        <div class="text-center text-md-start">
                            Menampilkan
                            <span class="fw-bold">1-{{ limit }}</span> Data dari
                            <span class="fw-bold">{{
                                lists.list_data.length
                            }}</span>
                            Data
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div
                            class="d-flex flex-column flex-md-row align-items-center justify-content-md-end"
                        >
                            <div class="mb-2 mb-md-0 me-md-2">
                                Baris per halaman
                            </div>
                            <select
                                class="form-control form-select"
                                style="max-width: 100px"
                                v-model="limit"
                                @change="changeLength()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Order ID
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Pesanan
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Cetak Invoice
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <a
                                        href="javascript:void(0)"
                                        @click="goToDetail(item)"
                                        class="text-link"
                                    >
                                        {{
                                            item.customer_name
                                                ? item.customer_name
                                                : "-"
                                        }}
                                    </a>
                                </td>
                                <td>
                                    {{ item.order_id ? item.order_id : "-" }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 capitalize blue-cro rounded-pill"
                                    >
                                        {{
                                            item.order_type === "reg"
                                                ? "Regular"
                                                : item.order_type === "walk_in"
                                                ? "Walk In"
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td>{{ item.origin ? item.origin : "-" }}</td>
                                <td>
                                    {{
                                        item.destination
                                            ? item.destination
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0)"
                                        @click="downloadInvoice(item.order_id)"
                                    >
                                        <img
                                            src="../assets/print.png"
                                            width="20"
                                            alt=""
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar pesanan
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "OrderCashier",
    data() {
        return {
            isFilter: false,
            search: "",
            limit: 10,
            totalPage: 0,
            currentPage: 0,
            lists: {
                list_data: [],
            },
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            list_loading: false,
            is_loading_data: false,
        };
    },
    created() {
        this.getLists();
        this.getRegency();
    },
    methods: {
        changeLength() {
            this.currentPage = 1;
            this.getLists();
        },
        clearFilter() {
            this.origin = null;
            this.destination = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.origin) {
                payload.origin_regency_id = this.origin?.id;
            }
            if (this.destination) {
                payload.destination_regency_id = this.destination?.id;
            }
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.list_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list_loading = false;
                });
        },
        goToDetail(item) {
            this.$router.push({
                name: "order-cashier-detail",
                query: {
                    order_id: item.order_id,
                },
            });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        downloadInvoice(id) {
            this.is_loading_data = true;
            var data = {
                receipt_code: id,
            };
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/order/invoice`, data, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    this.is_loading_data = false;
                    const link = res.data.data.link;
                    const isChrome =
                        /Chrome/.test(navigator.userAgent) &&
                        /Google Inc/.test(navigator.vendor);
                    const isAndroid = /Android/.test(navigator.userAgent);

                    if (isChrome && isAndroid) {
                        window.location.href = link;
                    } else {
                        const newWindow = window.open();
                        if (newWindow) {
                            newWindow.opener = null;
                            newWindow.location = link;
                        } else {
                            window.location.href = link;
                        }
                    }
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.filter-button {
    display: flex;
    gap: 1rem;
}
@media only screen and (max-width: 800px) {
    .btn-pesanan {
        width: 100%;
    }
}
</style>
