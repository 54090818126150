import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import helpers from "./helpers";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import * as moment from "moment/moment";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import firebase from "firebase/app";
import "firebase/messaging";
import "./assets/scss/app.scss";

import BlankLayout from "./layouts/BlankLayout.vue";
import AdminLayout from "./layouts/AdminLayout.vue";
import LoginLayout from "./layouts/LoginLayout.vue";
import ChatLayout from "./layouts/ChatLayout.vue";
import Notifications from "vue-notification";
import "primeicons/primeicons.css";
import * as VueGoogleMaps from "vue2-google-maps";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueInputAutowidth from "vue-input-autowidth";

Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_KEY_GMAPS,
        libraries: "places",
    },
});

firebase.initializeApp({
    apiKey: "AIzaSyAGLCrZRNPtc6q_ywCL5HcZ2_7Os5AuiIM",
    authDomain: "troben-silog.firebaseapp.com",
    projectId: "troben-silog",
    storageBucket: "troben-silog.appspot.com",
    messagingSenderId: "386298750543",
    appId: "1:386298750543:web:083fc1a3bc062b3969ec3a",
    measurementId: "G-YG5ZDYE1KG"
});

navigator.serviceWorker
    .register("firebase-messaging-sw.js")
    .then((registration) => {
        const messaging = firebase.messaging();
        messaging.useServiceWorker(registration);
    })
    .catch((err) => {
        console.log(err);
    });

Vue.component("blank-layout", BlankLayout);
Vue.component("admin-layout", AdminLayout);
Vue.component("login-layout", LoginLayout);
Vue.component("chat-layout", ChatLayout);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.use(VueInputAutowidth);

Vue.config.productionTip = false;

Vue.mixin({
    methods: {
        // date(date, format){
        //     return moment(date).format(format)
        // },
    },
});

Object.keys(helpers).forEach((key) => {
    Vue.prototype[key] = helpers[key];
});

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
