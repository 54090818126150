<template>
    <div>
        <div class="notifications-container">
            <div
                v-for="(msg, index) in messages"
                :key="index"
                class="notification-box shadow mt-3 me-3"
            >
                <div class="notification-indicator"></div>
                <div
                    class="d-flex justify-content-end cursor-pointer mb-2 close-button"
                    @click="removeNotification(index)"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                    >
                        <path
                            d="M12.3334 1.66675L1.66673 12.3335"
                            stroke="#3E4E5C"
                            stroke-width="1.75"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1.66663 1.66675L12.3333 12.3335"
                            stroke="#3E4E5C"
                            stroke-width="1.75"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
                <div class="d-flex align-items-center">
                    <img
                        src="/assets/bell-unread.png"
                        alt="bell unread"
                        class="me-3"
                    />
                    <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                            <p class="mb-0 text-unread">{{ msg.title }}</p>
                            <p class="mb-0 text-gray">Baru Saja</p>
                        </div>
                        <p class="mb-0">{{ msg.body }}</p>
                    </div>
                </div>
            </div>
        </div>
        <component :is="layout" :roles="roles" />
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/messaging";
import Api from "./api/Api";
import { EventBus } from "./eventBus";

export default {
    name: "App",
    computed: {
        layout() {
            let layoutVar = this.$route?.meta
                ? this.$route?.meta.layout
                : "default";
            if (layoutVar === undefined || layoutVar == "undefined") {
                layoutVar = "default";
            }
            return layoutVar + "-layout";
        },
    },
    data() {
        return {
            jwtToken: localStorage.getItem("token"),
            roles: [],
            messages: [],
            fcmToken: localStorage.getItem("fcmToken"),
        };
    },

    created() {
        if (this.jwtToken == null) {
            this.$router.push("/");
        }
        this.setupNotifications();
    },
    filters: {
        formatExpTime(expTime) {
            const date = new Date(expTime * 1000);
            return date.toLocaleString();
        },
    },
    methods: {
        setupNotifications() {
            var Notification =
                window.Notification ||
                window.mozNotification ||
                window.webkitNotification;
            var was_questioned = false;
            if (Notification.permission == "default") {
                was_questioned = true;
            }
            Notification.requestPermission(function (permission) {
                if (was_questioned) {
                    console.log(
                        "User was asked. New permission is: " + permission
                    );
                }
                if ("permissions" in navigator) {
                    navigator.permissions
                        .query({ name: "notifications" })
                        .then(function (notificationPerm) {
                            notificationPerm.onchange = function () {
                                console.log(
                                    "User decided to change his settings. New permission: " +
                                        notificationPerm.state
                                );
                            };
                        });
                }
            });

            try {
                firebase
                    .messaging()
                    .requestPermission()
                    .then(() => {
                        console.log("Notification permission granted");
                        firebase
                            .messaging()
                            .getToken()
                            .then((token) => {
                                if (this.jwtToken && !this.fcmToken) {
                                    this.sendNotificationSync(token);
                                }
                                this.receiveMessage();
                            });
                    })
                    .catch((err) => {
                        console.log("Unable to get token ", err);
                    });
            } catch (e) {
                console.log(e);
            }
        },
        receiveMessage() {
            try {
                firebase.messaging().onMessage((payload) => {
                    console.log(payload);
                    const newMessage = {
                        title: payload.notification.title,
                        body: payload.notification.body,
                    };
                    this.messages.push(newMessage);
                    setTimeout(() => {
                        const index = this.messages.indexOf(newMessage);
                        if (index > -1) {
                            this.messages.splice(index, 1);
                        }
                    }, 5000);
                    EventBus.$emit("newNotification");
                });
            } catch (e) {
                console.log(e);
            }
        },
        sendNotificationSync(token) {
            const userAgent = navigator.userAgent;
            const syncData = {
                token: token,
                device_id: userAgent,
                type: "create",
            };

            const headers = {
                Authorization: "Bearer " + localStorage.getItem("token"),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user/notification/sync`,
                syncData,
                { headers: headers }
            )
                .then(() => {
                    localStorage.setItem("fcmToken", token);
                    localStorage.setItem("deviceId", userAgent);
                })
                .catch((err) => {
                    console.log("Notification sync failed", err);
                });
        },
        removeNotification(index) {
            this.messages.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.notifications-container {
    position: fixed;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.text-unread {
    color: #329a5f !important;
}

.notification-box {
    position: relative;
    padding: 15px 25px 20px 25px;
    background-color: #fff;
    border: 1px solid #e6e6e8;
    width: 35rem;
    margin-bottom: 10px;
}

.notification-indicator {
    width: 5px;
    background-color: #40bf76;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.close-button {
    cursor: pointer;
}

@media (max-width: 768px) {
    .notification-box {
        width: 90%;
    }

    .notifications-container {
        right: 5%;
        left: 5%;
    }
}
</style>
