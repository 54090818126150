<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="d-flex">
                <div
                    class="crm-avatar me-3"
                    :style="{
                        'background-image': 'url(' + user.avatar + ')',
                    }"
                ></div>
                <div>
                    <div class="size-24 fw-bold">
                        {{ user.name }}
                    </div>
                    <span class="text-gray-dashboard">{{
                        user.role_label
                    }}</span>
                    <!-- <span
                        class="text-gray-dashboard"
                        v-for="(role, index) in user.roles"
                        :key="index"
                    >
                        {{ role
                        }}<span v-if="index < user.roles.length - 1">,</span>
                    </span> -->
                    <div class="mt-2">
                        <template v-if="user.is_active == true">
                            <small class="tag green-solid radius px-4"
                                >Active</small
                            >
                        </template>
                        <template v-else>
                            <small class="tag red-solid radius px-4"
                                >Not Active</small
                            >
                        </template>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <img src="../../assets/phone.png" width="18" alt="" />
                <div class="ms-3 size-16">
                    {{ user.phone }}
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <img src="../../assets/email.png" width="18" alt="" />
                <div class="ms-3 size-16">
                    {{ user.email }}
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <img src="../../assets/location.png" width="18" alt="" />
                <div class="ms-3 size-16">
                    {{ user.address ? user.address : "-" }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";

export default {
    components: {},
    name: "CrmProfile",
    data() {
        return {
            profile: {},
            user: {},
        };
    },
    created() {
        this.getUserLogin();
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/auth/account`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getProfile() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/account`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.profile = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.crm-avatar {
    width: 70px;
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 1px solid #3782e6;
}
</style>
