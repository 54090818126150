<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Klaim Pesanan</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-yellow btn-filter px-3"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4">
                        <label class="fw-bold mb-1">Kota Asal</label>
                        <multiselect
                            v-model="origin"
                            :options="origins"
                            label="name"
                            track-by="id"
                            placeholder="Cari dan pilih kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-8">
                        <label class="fw-bold mb-1">Kota Tujuan</label>
                        <div class="row">
                            <div class="col-md-6">
                                <multiselect
                                    v-model="destination"
                                    :options="destinations"
                                    label="name"
                                    track-by="id"
                                    placeholder="Cari dan pilih kota tujuan"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex">
                                    <button
                                        class="btn btn-outline-yellow px-5 me-3"
                                        @click="clearFilter()"
                                        :disabled="
                                            origin == null &&
                                            destination == null
                                        "
                                    >
                                        Clear
                                    </button>
                                    <button
                                        class="btn btn-green px-5"
                                        :disabled="
                                            origin == null &&
                                            destination == null
                                        "
                                        @click="filterData()"
                                    >
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">1-{{ limit }}</span> Data dari
                    <span class="fw-bold">{{ lists.list_data.length }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="onLimitChange"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">Deal ID</th>
                                <th class="text-center" scope="col">
                                    Jenis Pesanan
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{
                                        item.customer_name
                                            ? item.customer_name
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{ item.deal_code ? item.deal_code : "-" }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 capitalize blue-cro rounded-pill"
                                    >
                                        {{
                                            item.order_type === "regular"
                                                ? "Regular"
                                                : item.order_type === "walk_in"
                                                ? "Walk In"
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td>
                                    {{
                                        item.origin_regency
                                            ? item.origin_regency
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.dest_regency
                                            ? item.dest_regency
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center" style="width: 100px">
                                    <button
                                        class="btn btn-green btn-sm w-100"
                                        @click="goToClaim(item)"
                                        v-if="!item.is_claim"
                                    >
                                        Claim Pesanan
                                        <i
                                            class="pi pi-arrow-up-right ms-1 size-12"
                                        ></i>
                                    </button>
                                    <button
                                        class="btn btn-primary btn-sm w-100"
                                        @click="goToDetail(item)"
                                        v-if="item.is_claim"
                                    >
                                        Lihat Detail
                                        <i class="pi pi-eye ms-1 size-12"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar pesanan
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <b-pagination-nav
                            v-if="lists.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="getLists()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "OrderProcess",
    data() {
        return {
            isFilter: false,
            search: "",
            limit: 10,
            totalPage: 0,
            currentPage: 1,
            lists: {
                list_data: [],
            },
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            list_loading: false,
        };
    },
    computed: {
        displayedRowsStart() {
            return (this.currentPage - 1) * this.limit + 1;
        },
        displayedRowsEnd() {
            return Math.min(
                this.currentPage * this.limit,
                this.lists.total_data
            );
        },
    },
    created() {
        this.getLists();
        this.getRegency();
    },
    methods: {
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        goToClaim(item) {
            this.$router.push({
                name: "order-process-create",
                query: {
                    claim_id: item.id,
                },
            });
        },
        goToDetail(item) {
            this.$router.push({
                name: "order-process-detail",
                query: {
                    order_id: item.order_id,
                },
            });
        },
        clearFilter() {
            this.origin = null;
            this.destination = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page: this.currentPage,
            };
            if (this.origin) {
                payload.origin_regency_id = this.origin?.id;
            }
            if (this.destination) {
                payload.destination_regency_id = this.destination?.id;
            }
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/claims`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.list_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list_loading = false;
                });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
