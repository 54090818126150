<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box mt-4 m-mt-0 position-relative">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="fw-bold">Notifikasi</h3>
                <a
                    href="javascript:void(0)"
                    @click="readNotif()"
                    class="text-primary"
                    v-if="notif_all.length !== 0"
                >
                    <u>Tandai semua telah dibaca</u>
                </a>
            </div>
            <a
                href="javascript:void(0)"
                v-for="notif in notif_all"
                :key="notif.id"
                @click="readNotif(notif.id, notif.meta.path)"
            >
                <div class="p-3 mt-2">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                class="notif-avatar-sidebar"
                                :src="
                                    notif.status === 'pending'
                                        ? '../assets/bell-unread.png'
                                        : '../assets/bell-read.png'
                                "
                                alt="notification bell"
                            />
                        </div>
                        <div class="w-100">
                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <div
                                    class="size-16 fw-semibold"
                                    :class="
                                        notif.status === 'pending'
                                            ? 'title-unread'
                                            : 'text-gray'
                                    "
                                >
                                    {{ notif.title }}
                                </div>
                                <div class="ms-auto text-gray">
                                    {{ notif.created_at }}
                                </div>
                            </div>
                            <div
                                :class="
                                    notif.status === 'pending'
                                        ? 'subtitle-unread'
                                        : 'text-gray'
                                "
                            >
                                {{ notif.subtitle }}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <div class="mt-4" v-if="notif_all.length === 0">
                <center>
                    <img src="../assets/no-notif.png" width="120" alt="" />
                    <div class="mt-3 text-gray-dashboard">
                        Belum ada notifikasi
                    </div>
                </center>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-if="notif_all.length > 0"
                    v-model="currentPage"
                    :number-of-pages="total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";

export default {
    data() {
        return {
            per_page: 10,
            currentPage: 0,
            notif_all: [],
            total_page: 0,
        };
    },
    created() {
        this.getNotif();
    },
    methods: {
        getNotif() {
            let payload = {
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/user/notification`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.notif_all = res.data.data.list_data;
                    this.total_page = res.data.data.total_page;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changePage() {
            this.getNotif();
        },
        readNotif(id = null, path = null) {
            if (path && !path.startsWith("/")) {
                path = "/" + path;
            }
            let data = id ? { id: id } : null;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user/notification/read`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.getNotif();
                    if (path) {
                        this.$router.push(path);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.title-unread {
    color: #329a5f !important;
}
.subtitle-unread {
    color: #3e4e5c !important;
}
.p-alert-notif {
    padding: 5px;
    left: 6px !important;
    top: 2px !important;
}
.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f7f7;
    width: 100%;
    border-radius: 50px;
}
input[type="radio"] {
    appearance: none;
    display: none;
}

label.tab-radio-label {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    cursor: pointer;
    width: 33.33%;
    margin: 5px;
    height: 22px;
    text-align: center;
    border-radius: 50px;
    overflow: hidden;
    color: #000;
}

input.tab-radio-input[type="radio"]:checked + label {
    background-color: #3d8824;
    color: #fff;
    transition: 0.3s;
}
.grayscale {
    filter: grayscale(1);
    /* background-color: #e0e0e0; */
    background-blend-mode: overlay;
}
.notif-avatar-sidebar {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.box-filter {
    background: #fff;
    padding: 15px !important;
    width: auto;
    position: absolute;
    z-index: 9999;
    left: 25px;
    top: 110px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}

.select_time_wrapper label span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: #fff;
    color: #808385;
    border: 2px solid #f7f7f7;
    padding: 2px 15px;
    border-radius: 20px;
}

.select_time_wrapper input:checked + span {
    background-color: #d8e7d3;
    cursor: pointer;
    color: #3d8824;
    border: 2px solid #d8e7d3;
    padding: 2px 15px;
    border-radius: 20px;
}
.custom-control-label::after {
    border-radius: 50% !important;
    width: 24px;
    height: 24px;
    top: 0px;
    color: transparent;
}
.form_bot_wrapper .custom-control-label::before {
    background-color: #ebebeb;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    top: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ebebeb;
}
</style>
